const getPercentageByKey = (data, key) => {
  const result = []
  for (const obj of data) {
    delete obj.Nonpartisan
    delete obj.Other

    const demPercentage = (
      (obj['Democratic Party'][key] / obj['Democratic Party']['total']) *
      100
    ).toFixed(2)
    const repPercentage = (
      (obj['Republican Party'][key] / obj['Republican Party']['total']) *
      100
    ).toFixed(2)

    const demObj = {
      emails: obj['Democratic Party'][key],
      total: obj['Democratic Party']['total'],
      percentage: parseFloat(demPercentage)
    }
    const repObj = {
      emails: obj['Republican Party'][key],
      total: obj['Republican Party']['total'],
      percentage: parseFloat(repPercentage)
    }

    result.push({
      date: obj.date.toUpperCase(),
      'Democratic Party': demObj,
      'Republican Party': repObj
    })
  }
  return result
}

module.exports = {
  getPercentageByKey
}
