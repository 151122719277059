import React from 'react'
// import RacismByParty from '../../../common/charts/RacismByParty'
import RacismOverTime from '../../../common/charts/RacismOverTime'

function RacismCharts() {
  return (
    <div className='p-6 bg-light'>
      {/* <div className="lg:col-span-3">
        <RacismByParty />
      </div>
      <div className="p-6 lg:col-span-4 bg-light">
        <RacismOverTime />
      </div> */}
     <RacismOverTime />
    </div>
  )
}

export default RacismCharts
